import React, { Component } from 'react';
import {Element, animateScroll as scroll} from 'react-scroll';

class Page extends Component {
  
  componentDidMount(){
    scroll.scrollToTop({duration: 500,smooth:false});
  }

  render(){
  let containerType = "container-fluid mb-3";
  if (this.props.fixedContainer) {
    containerType = "container mb-3";
  }
  return <div className={containerType}>
    <div className="row">
      <div className="col-12 text-center">
        <Element name="the-target"><h1 className="display-1 mt-4 mb-4 animated slideInLeft">{this.props.title}</h1></Element>
        <p className="lead animated slideInRight">{this.props.sub}</p>
      </div>
    </div>
    <div className="row">
      {this.props.skip12 ? this.props.children :
        <div className="col-12">
          {this.props.children}
        </div>}
    </div>
  </div>
  }
}
export default Page;
