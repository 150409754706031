import React, { Component } from 'react';
import Page from "../components/Page";

class MapPage extends Component {

  componentDidMount() {

    const google = window.google;
    let map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 28.5383, lng: -81.3792 },
      zoom: 10
    });

    var bounds = new google.maps.LatLngBounds();

    this.props.shows.map(show => {
      if (show.venue.lng && show.venue.lat) {

        var marker = new google.maps.Marker({
          map: map,
          position: { lat: show.venue.lat, lng: show.venue.lng },
          animation: google.maps.Animation.DROP,
          title: show.displayName
        });

        marker.addListener('click', (function (uri) {
          return function () {
            window.open(uri);
          }
        })(show.uri));

        bounds.extend({ lat: show.venue.lat, lng: show.venue.lng });
      }

      return true;
    });

    map.fitBounds(bounds);

  }

  render() {
    return (
      <Page title="Map">
        <div id="map">
          Loading...
		    </div>
      </Page>
    );
  }
}

export default MapPage;