import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import "../components/Fullscreenmenu.css";

class FullscreenMenu extends Component {

  toggleNavbar = (e) => {
    let nav = document.getElementById("fullscreen-nav");
    nav.classList.toggle("fullscreen-nav-expanded");
    document.body.classList.toggle("no-scroll");
  }

  render() {

    let links = [
      { label: "Home", path: "/" },
      { label: "Search", path: "/search" },
      { label: "Explore", path: "/explore" },
      { label: "Map", path: "/map" },
      { label: "Filters", path: "/filters" }
    ]

    return (
        <>
        <nav className="navbar navbar-dark bg-black fixed-top" id="nav">
          <Link to="/" className="navbar-brand" id="brand">Orlando <span className="text-danger">Rock Shows</span></Link>
          <button onClick={this.toggleNavbar} className="navbar-toggler navbar-toggler-right bg-dark"><span className="navbar-toggler-icon"></span></button>
        </nav>

        <nav id="fullscreen-nav" onClick={this.toggleNavbar}>
          <div>
            {
              links.map((link, index) => {

                let classes = "display-ors ";
                if (this.props.location.pathname === link.path) {
                  classes += "text-danger";
                }

                return <Link key={index} to={link.path} className={classes}>{link.label}</Link>

              })
            }
            </div>
        </nav>
        </>
    );
  }
}

export default withRouter(FullscreenMenu);