import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faMapMarkerAlt, faHiking} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

class Hero extends Component {
  render() {
    
    const CTAs = [
      {path:"/search",label:"Search", subText:"Search Dates", icon:faCalendarDay},
      {path:"/explore",label:"Explore", subText:"See Upcoming Shows", icon:faHiking},
      {path:"/map",label:"Map", subText:"View Map of Events", icon:faMapMarkerAlt}
    ]

    return (
      <div className="concertBackground" id="hero">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-1 overflow-hidden">
                <img src="/images/logo.svg" className="animated bounceInDown" alt="Orlando Rock Shows logo" />
            </div>
            {CTAs.map((CTA,CTA_index)=><div className="col-md-4 mb-3" key={CTA_index}>
              <Link to={CTA.path} className="btn btn-outline-light btn-block animated bounceInUp">
                <div className="display-5"><FontAwesomeIcon icon={CTA.icon}/> {CTA.label}</div>
                <div className="text-uppercase"><small>{CTA.subText}</small></div>
              </Link>
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;