import React, { Component } from 'react';

class Time extends Component {
  render() {
    let main = this.props.children;
    let newMain = "-";
    if (main) {
      newMain = main.substring(0, 5);
    }
    return <span className="text-nowrap">{newMain}</span>
  }
}

export default Time;