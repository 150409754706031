import React, { Component } from 'react';
import Page from "../components/Page";
import {withRouter} from "react-router-dom";
import axios from "axios";

class ShowPage extends Component {

  state = {
    mainArtistId:"",
    src:"",
    displayName: "",
    type:"",
    city:""
  }

  componentDidMount(){

    let url = "https://api.songkick.com/api/3.0/events/"+this.props.match.params.id+".json?apikey=2ieN5wifxwbxFl0x";
    let that = this;

    axios.get(url)
      .then((res) => {
        let {displayName, type} = res.data.resultsPage.results.event;
        let city = res.data.resultsPage.results.event.location.city;
        let mainArtistId = res.data.resultsPage.results.event.performance[0].artist.id;
        let src = 'https://images.sk-static.com/images/media/profile_images/artists/' + mainArtistId + '/col6';
        console.log("Show response", res.data.resultsPage.results.event);
        that.setState({
          mainArtistId,
          src,
          displayName,
          type,
          city
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <>
      <Page title="Show" fixedContainer={true} skip12={true}>
      <div className="col-12">
      <h1 className="display-4 text-center">{this.state.displayName}</h1>
      </div>
      <div className="col-5">
        <img src={this.state.src} className="img-fluid" alt="main artist"/>      
      </div>
      <div className="col-6">
        <h2>{this.state.type}</h2>
        <h3>{this.state.city}</h3>
      </div>
      </Page>
      </>
    );
  }
}

export default withRouter(ShowPage);