import React, { Component } from 'react';
import Page from "../components/Page";
import axios from "axios";
import ShowTile from "../components/ShowTile";
import { scroller } from 'react-scroll';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


class SearchPage extends Component {

  constructor() {
    super();
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      startDate: undefined,
      endDate: undefined,
      data: [],
      noDataReturned: false,
      isFocused: true
    };
  }

  handleResetClick = () => {
    this.setState(this.getInitialState());
  }

  focusedInput = () => {

  }

  search = () => {
    const { startDate, endDate } = this.state;
    if (startDate && endDate) {
      const outFrom = startDate.toISOString().substr(0, 10);
      const outTo = endDate.toISOString().substr(0, 10)
      let that = this;
      axios.get('https://api.songkick.com/api/3.0/metro_areas/3733/calendar.json?apikey=2ieN5wifxwbxFl0x&per_page=48&min_date=' + outFrom + "&max_date=" + outTo)
        .then((res) => {
          if (res.data.resultsPage.totalEntries > 0) {
            that.setState({
              data: res.data.resultsPage.results.event,
              noDataReturned: false
            }, () => {
              scroller.scrollTo('ors-results', {
                duration: 1000,
                offset: -60,
                smooth: 'easeInOutQuart'
              });
            });
          } else {
            that.setState({
              data: [],
              noDataReturned: true
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {

    return (
      <>
        <Page title="Search" skip12={true}>

          <div className="col-sm-8 text-center mx-auto py-3 mb-3">
            <div className="form-group">
              <label className="d-block">Dates</label>
              <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              hideKeyboardShortcutsPanel={true}
              minimumNights={0}
            />
            </div>
            {this.state.startDate && this.state.endDate ? <button className="btn btn-success text-white mr-3" onClick={this.search}>Search</button> : null}
            {this.state.startDate || this.state.endDate ? <button className="btn btn-light" onClick={this.handleResetClick}>Clear</button> : null }
          </div>

          {this.state.data.length ?
            <div className="col-12">
              <h2 id="ors-results" className="display-3">Results</h2>
            </div> : null}

          {this.state.noDataReturned ?
            <div className="col-12">
              <h2 className="display-4 text-danger text-center">No Data Available</h2>
            </div> : null}

          {this.state.data.map((show) => {
            let imageId = null;
            let title = show.displayName;

            if(show.performance.length){
              imageId = show.performance[0].artist.id;
              title = show.performance[0].artist.displayName;
            }
            return <ShowTile
              imageId={imageId}
              title={title}
              date={show.start.date}
              venue={show.venue.displayName}
              key={show.id}
              link={show.uri}
              showId={show.id} />
          })}

        </Page>
      </>
    );
  }
}

export default SearchPage;