import React, { Component } from 'react';
import Page from "../components/Page";
import Date from "../components/Date";
import Time from "../components/Time";

class TablePage extends Component {

  render() {
    return (
      <Page title="Table">
        <table className="table table-striped table-responsive-lg">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Type</th>
              <th>Artist(s)</th>
              <th>Venue</th>
              <th>City</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {this.props.shows.map((show) => {

              let artists = show.performance.map(artist=>{
                return artist.displayName;
              });

              let artistsDisplay;
              
              if(artists.length > 1){
                let lastArtist = artists.splice(-1);
                artistsDisplay = artists.join(", ") + " and " + lastArtist;
              }else{
                artistsDisplay = artists.join(".");
              }

              return <tr key={show.id}>
                <td><Date>{show.start.date}</Date></td>
                <td><Time>{show.start.time}</Time></td>
                <td>{show.type}</td>
                <td>{artistsDisplay}</td>
                <td className="text-nowrap">{show.venue.displayName}</td>
                <td className="text-nowrap">{show.location.city}</td>
                <td>
                  <a href={show.uri} className="btn btn-danger btn-sm">View Details</a>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </Page>
    );
  }
}

export default TablePage;