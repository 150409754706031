import React, { Component } from 'react';

class Footer extends Component {
  render() {

    var year = new Date().getFullYear();

    return (
      <div className="container-fluid bg-black">
        <div className="row text-center text-light">
          <div className="col-12 pt-3">
            <p>
              <a href="http://www.songkick.com" className="text-light"><img src="/images/sk.png" style={{ height: "40px" }} alt="Powered by Songkick logo." /></a>
              <br />Orlando Rock Shows &copy; {year}
            </p>
          </div>
        </div>
      </div >
    );
  }
}

export default Footer;