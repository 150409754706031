import React, { Component } from 'react';
import ShowTile from "../components/ShowTile";
import Page from "../components/Page";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class DiscoverPage extends Component {

  state = {
    shows: [],
    page: 1,
    loading: false,
    shouldStopPullingMoreShows: false,
    pageFetchLimit: 5
  }

  componentDidMount() {
    this.getMore();
  }

  getMore = () => {

    let that = this;
    that.setState({
      loading: true
    });

    if (!this.state.shouldStopPullingMoreShows) {
      axios.get('https://api.songkick.com/api/3.0/metro_areas/3733/calendar.json?apikey=2ieN5wifxwbxFl0x&per_page=8&page=' + this.state.page)
        .then((res) => {
          that.setState({
            shows: [...that.state.shows, ...res.data.resultsPage.results.event],
            page: that.state.page + 1,
            loading: false
          })
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (this.state.page > this.state.pageFetchLimit) {
      this.setState({ shouldStopPullingMoreShows: true });
    }
  }

  render() {
    return (
      <>
        <Page title="Explore" sub="Displaying upcoming shows" skip12={true}>
          {this.state.shows.map((show) => {

            let imageId = null;
            let title = show.displayName;

            if (show.performance.length) {
              imageId = show.performance[0].artist.id;
              title = show.performance[0].artist.displayName;
            }

            return <ShowTile
              imageId={imageId}
              title={title}
              date={show.start.date}
              venue={show.venue.displayName}
              key={show.id}
              link={show.uri}
              showId={show.id} />
          })}
          <div className="col-12">
            {// Loading spinner.
              this.state.loading ?
                <div className="text-center"><FontAwesomeIcon icon={faSpinner} size="3x" spin /></div>
                : null
            }

            {// Hide "Load More" button during fetching and when limit of pages is reached.
              !this.state.loading && !this.state.shouldStopPullingMoreShows ?
                <button className="btn btn-block btn-dark" onClick={this.getMore}>Load More</button>
                : null
            }
          </div>
        </Page>
      </>
    );
  }
}

export default DiscoverPage;