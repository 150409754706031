import React, { Component } from 'react';
import formatDate from "../logic/DateLogic";

class Date extends Component {
  render() {
    let date = this.props.children;
    let newDate = formatDate(date);
    if (this.props.plain) {
      return newDate;
    } else {
      return <span className="text-nowrap">{newDate}</span>
    }
  }
}

export default Date;