import React, { Component } from 'react';
import Page from "../components/Page";
import _ from "lodash";
import formatDate from "../logic/DateLogic";

class FiltersPage extends Component {

  state = {
    venues: [],
    dates: [],
    data: [],
    dataForView: [],
    venueFilter: null,
    dateFilter: null
  }

  componentDidMount() {
    let allVenues = _.map(this.props.shows, 'venue.displayName');
    let venues = _.orderBy(_.uniq(allVenues));

    let allDates = _.map(this.props.shows, 'start.date');
    let dates = _.orderBy(_.uniq(allDates));

    this.setState({ venues, dates, data: this.props.shows, dataForView: this.props.shows });
  }

  filterResults() {
    let dataForView = this.state.data;
    if (this.state.venueFilter || this.state.dateFilter) {
      dataForView = this.state.data.filter(show => {
        if (this.state.venueFilter && show.venue.displayName === this.state.venueFilter) { return true; }
        if (this.state.dateFilter && show.start.date === this.state.dateFilter) { return true; }
        return false;
      });
    }
    this.setState({ dataForView });
  }

  handleVenues = (e) => {
    this.setState({ venueFilter: e.target.value }, this.filterResults);
  }

  handleDates = (e) => {
    this.setState({ dateFilter: e.target.value }, this.filterResults);
  }

  resetForm = (e) => {
    e.preventDefault();
    document.getElementById("filtersForm").reset();
    this.setState({
      venueFilter: null,
      dateFilter: null
    }, this.filterResults);
  }

  render() {
    return (
      <Page title="Filters" skip12={true} fixedContainer={true}>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form id="filtersForm">
                <div className="form-group">
                  <label htmlFor="venueSelect">Venues</label>
                  <select className="form-control" id="venueSelect" onChange={this.handleVenues}>
                    <option value=""></option>
                    {this.state.venues.map(venue => <option value={venue} key={venue}>{venue}</option>)}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="dateSelect">Dates</label>
                  <select className="form-control" id="dateSelect" onChange={this.handleDates}>
                    <option value=""></option>
                    {this.state.dates.map(date => {
                      return <option value={date} key={date}>{formatDate(date)}</option>;
                    })}
                  </select>
                </div>
              </form>
              <button className="btn btn-danger" onClick={this.resetForm}>Clear Filters</button>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h2 className="mt-3 mb-3">Results ({this.state.dataForView.length})</h2>
          {
            this.state.venueFilter && this.state.dateFilter ?
              <div className="alert alert-warning" role="alert">Attention: The results below are a <b>union</b> of all selected filters.</div> : null
          }
          <ul>
            {this.state.dataForView.map(show => <li key={show.id}>{show.displayName} - <a href={show.uri} className="text-dark font-weight-bold">View Details</a></li>)}
          </ul>
        </div>
      </Page>
    );
  }
}

export default FiltersPage;