import React, { Component } from 'react';
import Date from "../components/Date";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

class ShowTile extends Component {
  render() {

    let src = '/images/guitar.jpeg';

    if (this.props.imageId) {
      src = 'https://images.sk-static.com/images/media/profile_images/artists/' + this.props.imageId + '/huge_avatar';
    }

    return (
      <div className="col-sm-6 col-lg-3">
        <div className="p-3 mb-3 card">
          <div className="concertBackground">
            <img alt="Band" className="img-fluid card-img" src={src} />
          </div>
          <h2 className="text-truncate display-4 mt-3 mb-3">{this.props.title}</h2>
          <p className="text-truncate"><FontAwesomeIcon icon={faCalendarDay} /> <Date>{this.props.date}</Date>
            <br /><FontAwesomeIcon icon={faLocationArrow} /> {this.props.venue}</p>
          <a href={this.props.link} target="_blank" rel="noopener noreferrer" className="btn btn-danger">View Details</a>
        </div>
      </div>
    );
  }
}

export default ShowTile;