import React, { Component } from 'react';
import Footer from "../components/Footer";
import FullscreenMenu from "../components/FullscreenMenu";
import Hero from "../components/Hero";
import TablePage from "../pages/TablePage";
import MapPage from "../pages/MapPage";
import FiltersPage from "../pages/FiltersPage";
import ExplorePage from "./ExplorePage";
import SearchPage from "../pages/SearchPage";
import ShowPage from "../pages/ShowPage";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class Home extends Component {

  state = {
    data: []
  }

  componentDidMount() {
    let that = this;
    axios.get('https://api.songkick.com/api/3.0/metro_areas/3733/calendar.json?apikey=2ieN5wifxwbxFl0x&per_page=48')
      .then((res) => {
        that.setState({
          data: res.data.resultsPage.results.event
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <FullscreenMenu/>
        {this.state.data.length > 0 ?
          <Switch>
            <Route exact path='/' render={() => <Hero />} />
            <Route path='/table' render={() => <TablePage shows={this.state.data} />} />
            <Route path='/map' render={() => <MapPage shows={this.state.data} />} />
            <Route path='/filters' render={() => <FiltersPage shows={this.state.data} />} />
            <Route path='/show/:id' render={() => <ShowPage />} />
            <Route path='/explore' render={() => <ExplorePage shows={this.state.data} />} />
            <Route path='/search' render={() => <SearchPage />} />
          </Switch>
          : <div className="text-center m-5"><FontAwesomeIcon icon={faSpinner} size="3x" spin/></div>}
        <Footer />
      </>
    );
  }
}

export default Home;